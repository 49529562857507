'use strict';

angular.module('appApp')
  .directive('primoPassword', function () {
    return {
      templateUrl: 'components/primo-password/primo-password.html',
      restrict: 'EA',
      scope: { model: '=model' },
      //link: function (scope, element, attrs) { }
      link: function () { }
    };
  });
